import React, { useEffect, useState } from 'react';
import { Button, Grid, InputAdornment, Paper, TextField, ThemeProvider, Typography } from '@mui/material';
// import theme from '../theme';
import { useDispatch, useSelector } from 'react-redux';
import { setConfigSlice } from '../store/realmSlice';
import { useNavigate } from 'react-router-dom';
import { KeyclockConfig } from '../auth/keycloackConfig';
import createConfig from '../auth';
import { setLoginConfigSlice } from '../store/loginRealmSlice';
import { error } from './Toast';
import axios from 'axios';

function Login() {
  const paperStyle = {
    padding: 20,
    height: 'auto',
    width: 350,
    margin: 'auto',
    backgroundcolor: '#edededd9',
  };
  let navigate = useNavigate();
  const btnstyle = { margin: '10px 0' };
  const inputstyle = { marginBottom: '20px' };
  const dispatch = useDispatch();
  const state = useSelector((state: any) => state.config);
  const keycloakConfig = createConfig();

  const [config, setConfig] = useState({
    // orgId: keycloakConfig.realm || 'greenkoIntranet',
    // idp: keycloakConfig.url || 'https://login.greenko.net',
    orgId: keycloakConfig.realm || 'newTestRealm',
    idp: keycloakConfig.url || 'https://gssfeed2.greenko.net',
    clientId: keycloakConfig.clientId || 'security-admin-console',
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfig({
      ...config,
      orgId: event.target.value,
    });
  };

  const checkToken = async () => {
    const instance: any = await KeyclockConfig.getInstance();
    if (instance) {
      if (state.idp && state.orgId) {
        navigate('/auth/dashboard');
      }
    } else {
      console.log('No token found');
      KeyclockConfig.destroyInstance();
      localStorage.clear();
    }
  };
  const checkRedirect = () => {
    const hash = window.location.hash.substr(1);
    const hashParams = new URLSearchParams(hash);
    const code = hashParams.get('code');
    const state = hashParams.get('state');
    const sessionState = hashParams.get('session_state');
    console.log(hash, hashParams, code, state, sessionState);
    if (code || state || sessionState) {
      checkToken();
    }
  };
  useEffect(() => {
    checkRedirect();
  }, []);

  const checkConfig = async (config: any) => {
    try {
      const response = await axios.get(`${config.idp}/realms/${config.orgId}`);
      if (response.status === 200) {
        localStorage.setItem('config', JSON.stringify(config));
        KeyclockConfig.createInstance();
        dispatch(setLoginConfigSlice(config));
        dispatch(setConfigSlice(config));
        // navigate('/auth/dashboard');
      } else {
        error('Invalid Ord Id or IDP URL');
      }
    } catch (err) {
      error('Invalid Ord Id or IDP URL');
    }
  };

  const handleSignIn = async () => {
    try {
      KeyclockConfig.destroyInstance();
      localStorage.clear();
      await checkConfig(config);
      navigate('/auth/dashboard');
      // debugger;
      // await KeyclockConfig.createInstance();
    } catch (error) {
      console.error(error);
    }
  };

  const handleIPDChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfig({
      ...config,
      idp: event.target.value,
    });
  };

  const handleClientIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfig({
      ...config,
      clientId: 'security-admin-console',
    });
  };

  // const imageurl = 'assets/greenko.svg';

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: '100vh', background: 'linear-gradient(136deg, #a8cf45f2, #0098da7a, #00000075)' }}
    >
      <Grid item xs={12} textAlign={'center'}>
        <img src="greenko.svg" alt="" style={{ width: '14%', marginBottom: '13px' }} />

        <Paper elevation={10} style={paperStyle}>
          <Typography variant="h6" gutterBottom sx={{ marginBottom: '23px' }}>
            {' '}
            Welcome to Dashboard App
          </Typography>
          <TextField
            label="Enter Org-Id"
            size="small"
            variant="outlined"
            fullWidth
            required
            color="primary"
            value={config.orgId}
            style={inputstyle}
            onChange={handleInputChange}
            InputProps={{
              startAdornment: <InputAdornment position="start">Org Id</InputAdornment>,
            }}
          />
          <TextField
            label="Enter IDP URL"
            size="small"
            variant="outlined"
            fullWidth
            required
            color="primary"
            style={inputstyle}
            value={config.idp}
            onChange={handleIPDChange}
            InputProps={{
              startAdornment: <InputAdornment position="start">IDP URL</InputAdornment>,
            }}
          />
          {/* <TextField
              label="Enter Client Id"
              size="small"
              variant="outlined"
              fullWidth
              required
              color="primary"
              style={inputstyle}
              value={config.clientId}
              onChange={handleClientIdChange}
              InputProps={{
                startAdornment: <InputAdornment position="start">Client Id</InputAdornment>,
              }}
            /> */}
          <Button onClick={handleSignIn} type="submit" color="secondary" variant="outlined" style={btnstyle} fullWidth>
            Sign in
          </Button>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default Login;
