import { Box, Breadcrumbs, Grid, Link, Stack, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import Layout from '../Layout';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import GroupsChild from './GroupsChild';
import { useDispatch, useSelector } from 'react-redux';
import GroupsMembers from './GroupsMembers';
import GroupsAttributes from './GroupAttributes';
import RoleMapping from './RoleMapping';
import { ApiHttpClient } from '../../interceptor/axiosInterceptor';
import { setConfigSlice } from '../../store/realmSlice';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function GroupDetails() {
  const [value, setValue] = React.useState(0);
  const state = useSelector((state: any) => state.config);
  const orgId = state.orgId;
  const initialState = useSelector((state: any) => state.initialConfig);
  const loginOrgId = initialState.orgId;
  const { clientid } = useParams();
  const dispatch = useDispatch();
  const [group, setGroup] = useState('');
  const accessArray = Object.values(state.realmAccess ?? {}).flat();

  const fetchData = async () => {
    try {
      const response1 = await ApiHttpClient.get(
        orgId !== loginOrgId
          ? `admin/${loginOrgId}/console/whoami?currentRealm=${orgId}`
          : `admin/${orgId}/console/whoami?currentRealm=${orgId}`,
      );
      const nameResponse1 = await ApiHttpClient.get(`admin/realms/${loginOrgId}/ui-ext/realms/names?`);
      let reformedState: any = {
        ...state,
        userId: response1.userId,
        realms: nameResponse1,
        realmAccess: response1.realm_access,
      };
      dispatch(setConfigSlice(reformedState));
      const url = `admin/realms/${orgId}/groups/${clientid}`;
      const response = await ApiHttpClient.get(url);
      setGroup(response ? response.name : '');
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [orgId]);

  const breadcrumbs = [
    <Link underline="hover" key="1" color="primary" component={RouterLink} to={`/auth/userdashboard/${orgId}/groups`}>
      Groups
    </Link>,
    <Typography key="2" color="text.primary">
      Group details
    </Typography>,
  ];

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <>
      <Grid sx={{ margin: 5, marginTop: 10 }}>
        <Layout>
          <Stack spacing={2} sx={{ marginTop: '10px' }}>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
              {breadcrumbs}
            </Breadcrumbs>
          </Stack>

          <Grid container spacing={3} sx={{ marginTop: '3px', paddingBottom: '15px' }}>
            <Grid item xs={12} lg={9}>
              <Typography variant="h5">{group}</Typography>
            </Grid>
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="Child Groups" {...a11yProps(0)} />
                  <Tab label="Members" {...a11yProps(1)} />
                  <Tab label="Attributes" {...a11yProps(2)} />
                  {accessArray.includes('manage-users') && <Tab label="Role Mapping" {...a11yProps(3)} />}{' '}
                </Tabs>
              </Box>

              <CustomTabPanel value={value} index={0}>
                <GroupsChild />
              </CustomTabPanel>

              <CustomTabPanel value={value} index={1}>
                <GroupsMembers />
              </CustomTabPanel>

              <CustomTabPanel value={value} index={2}>
                <GroupsAttributes />
              </CustomTabPanel>
              {accessArray.includes('manage-users') && (
                <CustomTabPanel value={value} index={3}>
                  <RoleMapping />
                </CustomTabPanel>
              )}
            </Box>
          </Grid>
        </Layout>
      </Grid>
    </>
  );
}

export default GroupDetails;
