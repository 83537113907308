import { Avatar, Button } from '@mui/material';
import { deepOrange } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
import { setConfigSlice } from '../store/realmSlice';
import { useEffect, useState } from 'react';
import { KeyclockConfig } from '../auth/keycloackConfig';
import { jwtDecode } from 'jwt-decode';
// import Keycloak from 'keycloak-js';

function Logout() {
  const state = useSelector((state: any) => state?.config);
  const initialState = useSelector((state: any) => state.initialConfig);
  const [userName, setUserName] = useState('');
  const dispatch = useDispatch();
  useEffect(() => {
    let token = KeyclockConfig.getInstance()?.token;
    const extractedUsername = decodeToken(token);
    setUserName(extractedUsername);
  }, []);

  const decodeToken = (token: any) => {
    try {
      const decodedToken: any = jwtDecode(token);
      return decodedToken?.preferred_username || decodedToken?.username || ''; // Adjust based on your token structure
    } catch (error) {
      console.error('Failed to decode token:', error);
      return '';
    }
  };
  const logouthandler = async () => {
    dispatch(
      setConfigSlice({
        idp: '',
        orgId: '',
        clientId: '',
        userId: '',
        realms: {},
      }),
    );
    dispatch(setConfigSlice(initialState));
    await KeyclockConfig.getInstance()?.logout({
      redirectUri: process.env.REACT_APP_URL,
    });
    localStorage.clear();
  };
  const orgId = state.orgId.slice(0, 2).toUpperCase();
  useEffect(() => {}, [state]);

  return (
    <>
      <Avatar sx={{ bgcolor: deepOrange[500], marginRight: 1 }}>{userName?.slice(0, 2)?.toUpperCase()}</Avatar>
      <Button
        sx={{
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}
        color="inherit"
        onClick={() => logouthandler()}
      >
        Logout
      </Button>
    </>
  );
}

export default Logout;
