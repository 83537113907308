import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
  TextField,
  Tooltip,
  Typography,
  styled,
  tableCellClasses,
  TablePagination,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Layout from '../Layout';
import { useDispatch, useSelector } from 'react-redux';
import { StyledTableRow } from '../../styles/tableStyles';
import { useEffect, useMemo, useRef, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { ApiHttpClient } from '../../interceptor/axiosInterceptor';
import { Link as RouterLink } from 'react-router-dom';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import Model from '../model';
import { error, success } from '../Toast';
import SkeletonLoader from '../SkeletonLoader';
import useDebouncedSearch from '../../hooks/useDebouncedSearch';
import ConfirmationDialog from '../deleteModal';
import { paginationRowsPerPage } from '../../const/strings';
import { setConfigSlice } from '../../store/realmSlice';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: '400px',
    maxWidth: '90%',
  },
  '& .MuiDialogTitle-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
function Groups() {
  const state = useSelector((state: any) => state.config);
  const orgId = useMemo(() => state.orgId, [state.orgId]);
  const initialState = useSelector((state: any) => state.initialConfig);
  const loginOrgId = initialState.orgId;
  const accessArray = Object.values(state.realmAccess ?? {}).flat();
  const [rows, setGroups] = useState([]);
  const [groupName, setGroupName] = useState('');
  const [editGroupId, setEditGroupId] = useState('');
  const [editGroup, setEditGroup] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [openChild, setOpenChild] = useState(false);
  const [parentGroupId, setParentGroupId] = useState('');
  const [childGroupName, setChildGroupName] = useState('');
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const initialRender = useRef(true);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [selectedUserId, setSelectedUserId]: any = useState(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
  const [count, setCount] = useState(10);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [firstValue, setFirstValue] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [searchTerm, setSearchTerm] = useDebouncedSearch((debouncedTerm: any) => {
    fetchData(debouncedTerm);
  });
  // Table data code starts

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    fetchData(searchTerm);
  }, [orgId, page, rowsPerPage]);

  const handleOpenConfirmation = (index: any, userId: any) => {
    setSelectedUserId(userId);
    setSelectedRowIndex(index);
    setOpenConfirmation(true);
  };

  const handleSearchInputChange = (e: any) => {
    const value = e.target.value;
    setSearchTerm(value);
    setFirstValue(0);
    setPage(0);
  };

  const fetchData = async (query = '') => {
    try {
      const response1 = await ApiHttpClient.get(
        orgId !== loginOrgId
          ? `admin/${loginOrgId}/console/whoami?currentRealm=${orgId}`
          : `admin/${orgId}/console/whoami?currentRealm=${orgId}`,
      );
      const nameResponse1 = await ApiHttpClient.get(`admin/realms/${loginOrgId}/ui-ext/realms/names?`);
      let reformedState: any = {
        ...state,
        userId: response1.userId,
        realms: nameResponse1,
        realmAccess: response1.realm_access,
      };
      dispatch(setConfigSlice(reformedState));
      const url = query
        ? `admin/realms/${orgId}/groups?first=${firstValue}&max=${rowsPerPage!}&search=${query}&global=false`
        : `admin/realms/${orgId}/groups?first=${firstValue}&max=${rowsPerPage!}`;
      const paginationUrl = query ? `admin/realms/${orgId}/groups?search=${query}` : `admin/realms/${orgId}/groups`;
      const paginationResponse = await ApiHttpClient.get(paginationUrl);
      setCount(paginationResponse.length);
      const response = await ApiHttpClient.get(url);
      const apiData = response;
      if (apiData) {
        // const formattedRows = apiData.map((group: { id: string; name: any; path: any; subGroup: any }, index: number) =>
        //   createData(index + 1, group.id, group.name, group.path, group.subGroup, group.name),
        // );
        setGroups(apiData);
      } else {
        console.error('API returned undefined data');
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: '7px !important',
    },
  }));

  function createData(SNo: number, id: string, Roles: string, path: any, subGroup: any, name: any) {
    return { SNo, id, Roles, path, subGroup, name };
  }

  const handleDeleteRow = async (index: number, id: string) => {
    try {
      const url = `/admin/realms/${orgId}/groups/${id}`;
      const response = await ApiHttpClient.delete(url, {});
      console.log(response, 'response');

      if (response === '') {
        fetchData();
        console.log('group deleted sucessfully');
        success('Group Deleted Sucessfully');
        setOpenConfirmation(false);
      } else {
        console.error('Failed to delete group:', response.statusText);
        error('Failed to delete group');
        setOpenConfirmation(false);
      }
    } catch (err) {
      console.error('Error deleting group:', err);
      error('Error deleting group');
      setOpenConfirmation(false);
    }
  };

  const handleUpdateGroup = async () => {
    try {
      const url = `/admin/realms/${orgId}/groups/${editGroupId}`;
      const payload = { ...editGroup, name: groupName };
      const response = await ApiHttpClient.put(url, payload);
      if (response === '') {
        success('Group updated successfully');
        console.log('Group updated successfully');
        fetchData('');
        handleClose();
      } else {
        handleClose();
        console.error('Failed to update group:', response.statusText);
        error('Failed to update group');
      }
    } catch (err) {
      handleClose();
      console.error('Error updating group:', err);
      error('Error updating group');
    }
  };

  // Table data code ends

  // Modal pop up code starts
  const handleClickOpen = () => {
    setEditGroupId('');
    setGroupName('');
    setIsEditMode(false);
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
    setGroupName('');
    setEditGroupId('');
    setIsEditMode(false);
  };

  const handleEditOpen = (id: string, name: string, row: any) => {
    setEditGroupId(id);
    setGroupName(name);
    setIsEditMode(true);
    setEditGroup(row);
    setOpenModal(true);
  };

  const handleCreateGroup = async () => {
    try {
      const url = `admin/realms/${orgId}/groups`;
      const payload = { name: groupName };
      const response = await ApiHttpClient.post(url, payload);
      if (response.status === 200) {
        success('Group Created Sucessfully');
        fetchData();
        setGroupName('');
        handleClose();
        setSearchTerm('');
      } else {
        handleClose();
        console.log('@@@@@');

        error('Failed to create group');
        console.error('Failed to create group:', response.statusText);
      }
    } catch (err: any) {
      console.error('Error creating group:', err);
      error(err?.['response']?.data || 'Error creating group');
    }
  };

  const handleCreateChildren = (id: string) => {
    setParentGroupId(id);
    setOpenChild(true);
  };

  const handleCloseChild = () => {
    setOpenChild(false);
    setChildGroupName('');
  };
  const handleCreateChildGroup = async () => {
    try {
      const url = `admin/realms/${orgId}/groups/${parentGroupId}/children`;
      const payload = { name: childGroupName };
      const response = await ApiHttpClient.post(url, payload);
      if (response.status === 200) {
        fetchData('');
        setChildGroupName('');
        handleCloseChild();
        success('Child Group created successfully');
      } else {
        console.error('Failed to create child group:', response.statusText);
        error('Failed to create child group');
      }
    } catch (err) {
      console.error('Error creating child group:', err);
      error('Error creating child group');
    }
  };

  const handleChangePage = (event: any, newPage: any) => {
    const firstValue = newPage * rowsPerPage;
    setFirstValue(firstValue);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFirstValue(0);
  };
  // Modal pop up code Ends

  return (
    <>
      <Grid sx={{ margin: 5, marginTop: 10 }}>
        <Layout>
          {loading ? (
            <SkeletonLoader rows={''} />
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12} lg={9}>
                <Typography variant="h6">Groups</Typography>
                <Typography variant="body1">
                  <Typography component="span" color={'red'}>
                    *Note:
                  </Typography>{' '}
                  Users are created at the organization level, and you will see all the users that have been created by
                  other apps.
                </Typography>
              </Grid>
              <Grid item xs={12} lg={3}>
                <Typography variant="h6" textAlign="right">
                  Org: {orgId.toUpperCase()}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  size="small"
                  fullWidth
                  id="outlined-basic"
                  value={searchTerm}
                  label="Search Group"
                  variant="outlined"
                  autoComplete="off"
                  onChange={handleSearchInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} textAlign="right">
                {accessArray.includes('manage-users') && (
                  <Button size="medium" variant="contained" onClick={handleClickOpen}>
                    Create Group
                  </Button>
                )}
              </Grid>

              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }}>
                    <TableHead sx={{ position: 'sticky', top: 0, zIndex: 1 }}>
                      <TableRow>
                        <StyledTableCell>S.No </StyledTableCell>
                        <StyledTableCell sx={{ cursor: 'pointer' }}>Groups </StyledTableCell>
                        <StyledTableCell>Action</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row: any, index) => (
                        <StyledTableRow key={index}>
                          <StyledTableCell component="th" scope="row">
                            {page * rowsPerPage + index + 1}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            <Link
                              underline="hover"
                              key="1"
                              color="primary"
                              component={RouterLink}
                              to={`/auth/userdashboard/${orgId}/groups/groupsdetails/${
                                row.id
                              }?groupName=${encodeURIComponent(row.name)}`}
                            >
                              {row.name}
                            </Link>
                          </StyledTableCell>

                          <StyledTableCell>
                            <Typography
                              sx={{
                                cursor: accessArray.includes('manage-users') ? 'pointer' : 'not-allowed',
                                marginRight: '10px',
                                color: accessArray.includes('manage-users') ? 'red' : 'gray',
                              }}
                              component="span"
                              onClick={() =>
                                accessArray.includes('manage-users') && handleOpenConfirmation(index, row.id)
                              }
                            >
                              <DeleteIcon />
                            </Typography>
                            <Tooltip title="Rename Group">
                              <Typography
                                sx={{
                                  cursor: accessArray.includes('manage-users') ? 'pointer' : 'not-allowed',
                                  marginRight: '10px',
                                  color: accessArray.includes('manage-users') ? '#1976d2' : 'gray',
                                }}
                                component="span"
                                onClick={() =>
                                  accessArray.includes('manage-users') && handleEditOpen(row.id, row.name, row)
                                }
                              >
                                <EditIcon />
                              </Typography>
                            </Tooltip>
                            <Tooltip title="Move Group to">
                              <Typography
                                sx={{
                                  cursor: accessArray.includes('manage-users') ? 'pointer' : 'not-allowed',
                                  marginRight: '10px',
                                  color: accessArray.includes('manage-users') ? '#1976d2' : 'gray',
                                }}
                                component="span"
                              >
                                <DriveFileMoveIcon />
                              </Typography>
                            </Tooltip>
                            <Tooltip title="Create Child Group">
                              <Typography
                                sx={{
                                  cursor: accessArray.includes('manage-users') ? 'pointer' : 'not-allowed',
                                  marginRight: '10px',
                                  color: accessArray.includes('manage-users') ? '#1976d2' : 'gray',
                                }}
                                component="span"
                                onClick={() => accessArray.includes('manage-users') && handleCreateChildren(row.id)}
                              >
                                <GroupAddIcon />
                              </Typography>
                            </Tooltip>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  component="div"
                  count={count}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={(event) => handleChangeRowsPerPage(event)}
                  rowsPerPageOptions={paginationRowsPerPage}
                  labelRowsPerPage={'Groups per Page'}
                />
              </Grid>
            </Grid>
          )}

          <Model
            open={openModal}
            handeleConfirm={isEditMode ? handleUpdateGroup : handleCreateGroup}
            title={isEditMode ? 'Update Group' : 'Create Group'}
            saveButtonName={isEditMode ? 'Update Group' : 'Create Group'}
            height="auto"
            data-modal-backdrop="static"
            cancelClick={() => handleClose()}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  fullWidth
                  label="Group Name"
                  id="outlined"
                  value={groupName}
                  onChange={(e) => setGroupName(e.target.value)}
                />
              </Grid>
            </Grid>
          </Model>

          <BootstrapDialog onClose={handleCloseChild} open={openChild}>
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
              Create Child Group
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleCloseChild}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    fullWidth
                    label="Child Group Name"
                    id="outlined"
                    value={childGroupName}
                    onChange={(e) => setChildGroupName(e.target.value)}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button size="medium" variant="contained" autoFocus onClick={handleCloseChild}>
                Cancel
              </Button>
              <Button size="medium" variant="contained" autoFocus onClick={handleCreateChildGroup}>
                Create Child Group
              </Button>
            </DialogActions>
          </BootstrapDialog>

          <ConfirmationDialog
            open={openConfirmation}
            onClose={() => setOpenConfirmation(false)}
            title="Confirm Deletion"
            content="Are you sure you want to delete this Group?"
            onConfirm={() => handleDeleteRow(selectedRowIndex, selectedUserId)}
          />
        </Layout>
      </Grid>
    </>
  );
}

export default Groups;
