import {
  Button,
  Checkbox,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  styled,
  tableCellClasses,
} from '@mui/material';
import SkeletonLoader from '../SkeletonLoader';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ApiHttpClient } from '../../interceptor/axiosInterceptor';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useDebouncedSearch from '../../hooks/useDebouncedSearch';
import { StyledTableRow } from '../../styles/tableStyles';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Model from '../model';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { error, success } from '../Toast';
import { paginationRowsPerPage } from '../../const/strings';

function GroupsMembers() {
  const state = useSelector((state: any) => state.config);
  const orgId = useMemo(() => state.orgId, [state.orgId]);
  const initialRender = useRef(true);
  const { clientid } = useParams();
  const [loading, setLoading] = useState(true);
  const [rows, setMembers] = useState([]);
  const dispatch = useDispatch();
  const [users, SetUsersData] = useState([]);
  const [selected, setSelected] = useState<string[]>([]);
  const [selectedUser, setSelectedUser] = useState<string[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [count, setCount] = useState(10);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [firstValue, setFirstValue] = useState(0);
  const [usersCount, setUsersCount] = useState(10);
  const [usersPage, setUsersPage] = useState(0);
  const [usersRowsPerPage, setUsersRowsPerPage] = useState(10);
  const [usersFirstValue, setUsersFirstValue] = useState(0);
  const accessArray = Object.values(state.realmAccess ?? {}).flat();
  const [searchTerm, setSearchTerm] = useDebouncedSearch((debouncedTerm: any) => {
    fetchData(debouncedTerm);
  });
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    fetchData(searchTerm);
  }, [orgId, page, rowsPerPage]);

  useEffect(() => {
    disableLeave();
  }, [selectedUser, rows]);

  const fetchData = async (query = '') => {
    try {
      const url = `admin/realms/${orgId}/groups/${clientid}/members?first=${firstValue}&max=${rowsPerPage!}&global=false`;
      const paginationUrl = query
        ? `admin/realms/${orgId}/groups/${clientid}/members?search=${query}`
        : `admin/realms/${orgId}/groups/${clientid}/members`;
      const paginationResponse = await ApiHttpClient.get(paginationUrl);
      setCount(paginationResponse.length);
      const response = await ApiHttpClient.get(url);
      const apiData = response ? response : [];

      if (apiData) {
        setMembers(apiData);
      } else {
        console.error('API returned undefined data');
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }

    try {
      const getUrl = query
        ? `admin/realms/${orgId}/users?first=${usersFirstValue}&max=${usersRowsPerPage!}&briefRepresentation=true&q=&search=${query}`
        : `admin/realms/${orgId}/users?first=${usersFirstValue}&max=${usersRowsPerPage!}&q=a`;
      const Usersresponse = await ApiHttpClient.get(getUrl);
      const paginationUrl =
        searchTerm == ''
          ? `admin/realms/${orgId}/users/count?search=${query}`
          : `admin/realms/${orgId}/users/count?q=&search=${query}`;
      const paginationResponse = await ApiHttpClient.get(paginationUrl);
      const membersUrl = `admin/realms/${orgId}/groups/${clientid}/members`;
      let memberResponse = await ApiHttpClient.get(membersUrl);
      const filteredUsers =
        Usersresponse.length > 0
          ? Usersresponse.filter((ele: any) => {
              return !memberResponse.some((item: any) => item.id === ele.id);
            })
          : [];
      SetUsersData(filteredUsers ? filteredUsers : []);
      setUsersCount(paginationResponse);
      setLoading(false);
    } catch (error) {
      console.log('Error Fetching data:', error);
      setLoading(false);
    }
  };

  const handleSearchInputChange = (e: any) => {
    const value = e.target.value;
    setSearchTerm(value);
    setUsersPage(0);
    setUsersFirstValue(0);
  };

  const handleClickOpen = async () => {
    const getUrl = searchTerm
      ? `admin/realms/${orgId}/users?first=${usersFirstValue}&max=${usersRowsPerPage!}&briefRepresentation=true&q=&search=${searchTerm}`
      : `admin/realms/${orgId}/users?first=${usersFirstValue}&max=${usersRowsPerPage!}&q=a&search=${searchTerm}`;
    const membersUrl = `admin/realms/${orgId}/groups/${clientid}/members`;
    let memberResponse = await ApiHttpClient.get(membersUrl);
    const Usersresponse = await ApiHttpClient.get(getUrl);
    const paginationUrl =
      searchTerm == ''
        ? `admin/realms/${orgId}/users/count?search=${searchTerm}`
        : `admin/realms/${orgId}/users/count?q=&search=${searchTerm}`;
    const filteredUsers =
      Usersresponse.length > 0
        ? Usersresponse.filter((ele: any) => {
            return !memberResponse.some((item: any) => item.id === ele.id);
          })
        : [];
    const paginationResponse = await ApiHttpClient.get(paginationUrl);
    // setSelected(memberResponse);
    setUsersCount(paginationResponse);
    SetUsersData(filteredUsers);
    setOpenModal(true);
  };

  const handleLeave = async (query = '') => {
    try {
      // Create an array of promises for the PUT requests
      if (query === '') {
        const deletePromises = selectedUser.map((id: string) => {
          const url = `admin/realms/${orgId}/users/${id}/groups/${clientid}`;
          return ApiHttpClient.delete(url, {});
        });

        // Wait for all PUT requests to complete
        await Promise.all(deletePromises);

        // Create an array of promises for the GET requests
        const getPromises = selectedUser.map((id: string) => {
          const url = `admin/realms/${orgId}/users/${id}/groups`;
          return ApiHttpClient.get(url);
        });

        // Wait for all GET requests to complete
        await Promise.all(getPromises);
        success(`${selectedUser.length} ${selectedUser.length > 1 ? 'users' : 'user'}  left the group`);
        setPage(0);
        setFirstValue(0);
        setSelectedUser([]);
      } else {
        setPage(0);
        setFirstValue(0);
        const deleteUrl = `admin/realms/${orgId}/users/${query}/groups/${clientid}`;
        await ApiHttpClient.delete(deleteUrl, {});
        const url = `admin/realms/${orgId}/users/${query}/groups`;
        await ApiHttpClient.get(url);
        success(`${selectedUser.length} ${selectedUser.length > 1 ? 'users' : 'user'} left the group`);
        setSelectedUser([]);
      }
      fetchData();
    } catch (err) {
      console.error('error::-', err);
      error('Failed to leave the group');
    }
  };

  const handleClose = () => {
    setSelected([]);
    setSearchTerm('');
    setOpenModal(false);
    setUsersRowsPerPage(10);
    setUsersPage(0);
    setUsersFirstValue(0);
  };

  const handleSubmit = async () => {
    try {
      const putPromises = selected.map((id: string) => {
        const url = `admin/realms/${orgId}/users/${id}/groups/${clientid}`;
        return ApiHttpClient.put(url, {});
      });

      await Promise.all(putPromises);

      const getPromises = selected.map((id: string) => {
        const url = `admin/realms/${orgId}/users/${id}/groups`;
        return ApiHttpClient.get(url);
      });
      await Promise.all(getPromises);
      selected.length > 0 &&
        success(`${selected.length} ${selected.length > 1 ? 'users' : 'user'}  added to the group`);
      setPage(0);
      setFirstValue(0);
      setSelected([]);
      fetchData();
      setSearchTerm('');
      selected.length > 0 && handleClose();
    } catch (err) {
      console.error('error::-', err);
      error('Failed to add member to the group');
    }
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = users.map((user: any) => user.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleSelectAllClickTable = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((user: any) => user.id);
      setSelectedUser(newSelected);
      return;
    }
    setSelectedUser([]);
  };

  const handleClick = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > -1) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleTableClick = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const selectedIndex = selectedUser.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedUser, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedUser.slice(1));
    } else if (selectedIndex === selectedUser.length - 1) {
      newSelected = newSelected.concat(selectedUser.slice(0, -1));
    } else if (selectedIndex > -1) {
      newSelected = newSelected.concat(selectedUser.slice(0, selectedIndex), selectedUser.slice(selectedIndex + 1));
    }
    setSelectedUser(newSelected);
  };

  const disableLeave = () => {
    return selectedUser.length === 0 || rows.length === 0;
  };
  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  const isUserSelected = (id: string) => selectedUser.indexOf(id) !== -1;

  const handleChangePage = (event: any, newPage: any) => {
    const firstValue = newPage * rowsPerPage;
    setFirstValue(firstValue);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFirstValue(0);
  };

  const handleUserChangePage = (event: any, newPage: any) => {
    const userFirstValue = newPage * usersRowsPerPage;
    setUsersFirstValue(userFirstValue);
    setUsersPage(newPage);
    handleMemberUsers(searchTerm, newPage, usersRowsPerPage, userFirstValue);
  };

  const handleUserChangeRowsPerPage = (event: any) => {
    const value = parseInt(event.target.value, 10);
    setUsersRowsPerPage(value);
    setUsersPage(0);
    setUsersFirstValue(0);
    handleMemberUsers(searchTerm, 0, value, 0);
  };

  const handleMemberUsers = async (searchTermRole = '', page: any, usersRowsPerPage: any, usersFirstValue: any) => {
    const getUrl = searchTermRole
      ? `admin/realms/${orgId}/users?first=${usersFirstValue}&max=${usersRowsPerPage!}&briefRepresentation=true&q=&search=${searchTermRole}`
      : `admin/realms/${orgId}/users?first=${usersFirstValue}&max=${usersRowsPerPage!}&q=a&search=${searchTermRole}`;
    const membersUrl = `admin/realms/${orgId}/groups/${clientid}/members`;
    let memberResponse = await ApiHttpClient.get(membersUrl);
    const paginationUrl =
      searchTermRole == ''
        ? `admin/realms/${orgId}/users/count?search=${searchTermRole}`
        : `admin/realms/${orgId}/users/count?q=&search=${searchTermRole}`;

    const Usersresponse = await ApiHttpClient.get(getUrl);
    const paginationResponse = await ApiHttpClient.get(paginationUrl);
    // const paginatedFiltered = pagination(Usersresponse, page, usersRowsPerPage);
    const filteredUsers =
      Usersresponse.length > 0
        ? Usersresponse.filter((ele: any) => {
            return !memberResponse.some((item: any) => item.id === ele.id);
          })
        : [];
    // setSelected(memberResponse);
    SetUsersData(filteredUsers ? filteredUsers : []);
    setUsersCount(paginationResponse);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: '7px !important',
    },
  }));
  return (
    <>
      {loading ? (
        <SkeletonLoader rows={''} />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            {/* <TextField
              size="small"
              fullWidth
              id="outlined-basic"
              value={searchTerm}
              label="Search Members"
              variant="outlined"
              autoComplete="off"
              onChange={handleSearchInputChange}
              disabled={rows.length === 0}
            /> */}
          </Grid>
          {accessArray.includes('manage-users') && (
            <Grid item xs={12} sm={6} textAlign="right">
              <Button
                size="medium"
                sx={{ marginRight: '20px', textAlign: 'center' }}
                variant="contained"
                disabled={disableLeave()}
                onClick={() => handleLeave()}
              >
                Leave
              </Button>

              <Button size="medium" variant="contained" onClick={() => handleClickOpen()}>
                Add Member
              </Button>
            </Grid>
          )}

          <Grid item xs={12}>
            {rows.length > 0 ? (
              <Grid>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }}>
                    <TableHead sx={{ position: 'sticky', top: 0, zIndex: 1 }}>
                      <TableRow>
                        <StyledTableCell>
                          <Checkbox
                            sx={{ color: 'white' }}
                            checked={rows.length > 0 && selectedUser.length === rows.length}
                            onChange={handleSelectAllClickTable}
                            inputProps={{ 'aria-label': 'select all users' }}
                          />
                        </StyledTableCell>
                        <StyledTableCell sx={{ cursor: 'pointer' }}>Groups </StyledTableCell>
                        <StyledTableCell>Action</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row: any, index) => {
                        const isItemSelected = isUserSelected(row.id);
                        return (
                          <StyledTableRow key={index}>
                            <StyledTableCell component="th" scope="row">
                              <Checkbox
                                checked={isItemSelected}
                                onChange={(event) => handleTableClick(event, row.id)}
                              />
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              {row.username}
                            </StyledTableCell>

                            <StyledTableCell>
                              <Typography sx={{ cursor: 'pointer', marginRight: '10px' }} component="span">
                                <IconButton
                                  onClick={() => handleLeave(row.id)}
                                  disabled={!accessArray.includes('manage-users')}
                                  color="error"
                                >
                                  {/* <DeleteIcon /> */}
                                  <Tooltip title="Leave" arrow placement="bottom">
                                    <ExitToAppIcon />
                                  </Tooltip>
                                </IconButton>
                              </Typography>
                              {/* <Tooltip title="Rename Group">
                                <Typography
                                  sx={{ cursor: 'pointer', marginRight: '10px' }}
                                  component="span"
                                  color={'primary'}
                                  // onClick={() => handleEditOpen(row.id, row.name, row)}
                                >
                                  <EditIcon />
                                </Typography>
                              </Tooltip> */}
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  component="div"
                  count={count}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={(event) => handleChangeRowsPerPage(event)}
                  rowsPerPageOptions={paginationRowsPerPage}
                  labelRowsPerPage={'Members per Page'}
                />
              </Grid>
            ) : (
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1" sx={{ textAlign: 'center', fontSize: '20px' }}>
                    No Users Found
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}

      <Model
        open={openModal}
        handeleConfirm={handleSubmit}
        title={'Add member'}
        saveButtonName={'Add'}
        data-modal-backdrop="static"
        //  buttonState={isSubmit}
        height="auto"
        width={'800px'}
        cancelClick={() => handleClose()}
      >
        <Grid item xs={12}>
          <Grid item xs={12} sm={6}>
            <TextField
              size="small"
              fullWidth
              id="outlined-basic"
              value={searchTerm}
              label="Search Members"
              variant="outlined"
              autoComplete="off"
              onChange={handleSearchInputChange}
            />
          </Grid>
          <TableContainer component={Paper} sx={{ maxHeight: 450, overflowY: 'auto', marginTop: '15px' }}>
            <Table sx={{ minWidth: 700 }}>
              <TableHead sx={{ position: 'sticky', top: 0, zIndex: 1 }}>
                <TableRow>
                  <StyledTableCell>
                    <Checkbox
                      sx={{ color: 'white' }}
                      checked={users.length > 0 && selected.length === users.length}
                      onChange={handleSelectAllClick}
                      inputProps={{ 'aria-label': 'select all users' }}
                    />
                  </StyledTableCell>
                  <StyledTableCell>Username</StyledTableCell>
                  <StyledTableCell>Email</StyledTableCell>
                  <StyledTableCell>First Name</StyledTableCell>
                  <StyledTableCell>Last Name</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={5}>Loading...</TableCell>
                  </TableRow>
                ) : (
                  users &&
                  users.map((user: any, index) => {
                    const isItemSelected = isSelected(user.id);
                    return (
                      <StyledTableRow key={user.id}>
                        <StyledTableCell component="th" scope="row">
                          <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, user.id)} />
                        </StyledTableCell>
                        <StyledTableCell>{user.username}</StyledTableCell>
                        <StyledTableCell>{user.email}</StyledTableCell>
                        <StyledTableCell>{user.firstName}</StyledTableCell>
                        <StyledTableCell>{user.lastName}</StyledTableCell>
                      </StyledTableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={usersCount}
            page={usersPage}
            onPageChange={handleUserChangePage}
            rowsPerPage={usersRowsPerPage}
            onRowsPerPageChange={(event) => handleUserChangeRowsPerPage(event)}
            rowsPerPageOptions={paginationRowsPerPage}
            labelRowsPerPage={'Members per Page'}
          />
        </Grid>
      </Model>
    </>
  );
}

export default GroupsMembers;
