import {
  Box,
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  styled,
  tableCellClasses,
} from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Layout from '../Layout';
import { useDispatch, useSelector } from 'react-redux';
import { StyledTableRow } from '../../styles/tableStyles';
import { useEffect, useRef, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';
import { ApiHttpClient } from '../../interceptor/axiosInterceptor';
import Model from '../model';
import { error, success } from '../Toast';
import SkeletonLoader from '../SkeletonLoader';
import useDebouncedSearch from '../../hooks/useDebouncedSearch';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ACTIONS, lowercaseLetters, numbers, paginationRowsPerPage, specialChars } from '../../const/strings';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ConfirmationDialog from '../deleteModal';
import { Link as RouterLink } from 'react-router-dom';
import { setConfigSlice } from '../../store/realmSlice';
function UsersList() {
  const state = useSelector((state: any) => state.config);
  const initialState = useSelector((state: any) => state.initialConfig);
  const loginOrgId = initialState.orgId;
  const orgId = state.orgId;
  const accessArray = Object.values(state.realmAccess ?? {}).flat();
  const [rows, setRows] = useState([]);
  const [personName, setPersonName] = useState<any>([]);
  const [actionNames, setActionNames] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const initialRender = useRef(true);
  const [showTooltip, setShowTooltip] = useState(false);
  const [passwordGenerated, setPasswordGenerated] = useState<string>('');
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [count, setCount] = useState(10);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [firstValue, setFirstValue] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [openResetModal, setResetModal] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [temporary, setTemporary] = useState(true);
  const [passwordId, setPasswordId] = useState('');
  const [searchTerm, setSearchTerm] = useDebouncedSearch((debouncedTerm: any) => {
    fetchData(debouncedTerm);
    setPage(0);
  });
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    setLoading(true);
    if (accessArray.includes('query-users')) {
      fetchData(searchTerm);
    }
  }, [orgId, page, rowsPerPage]);

  useEffect(() => {
    if (passwordGenerated) {
      setFormData({
        ...formData,
        password: passwordGenerated,
        confirmPassword: passwordGenerated,
      });
    }
  }, [passwordGenerated]);
  const handleResetPassword = async (id: any) => {
    setResetModal(true);
    setPasswordId(id);
  };
  const resetPassword = async () => {
    if (password !== confirmPassword) {
      error('Passwords do not match');
      return;
    }
    if (password === '' || confirmPassword === '') {
      error('Password Should Not Be Empty');
      return;
    }
    try {
      const url = `admin/realms/${orgId}/users/${passwordId}/reset-password`;
      const payload: any = { temporary: temporary, type: 'password', value: confirmPassword };
      const response = await ApiHttpClient.put(url, payload);
      success('Password Reset Successfully');
    } catch (err: any) {
      console.error('Error creating group:', err);
      error(err?.['response']?.data || 'Error Reset Password');
    }
    setResetModal(false);
    setPassword('');
    setConfirmPassword('');
    setTemporary(true);
  };
  const handleDisable = async (data: any) => {
    try {
      const url = `/admin/realms/${orgId}/users/${data?.id}`;
      const payload = { ...data, enabled: !data?.enabled };

      const response = await ApiHttpClient.put(url, payload);
      if (response === '') {
        success('User updated successfully');
        console.log('User updated successfully');
        fetchData('');
      } else {
        console.error('Failed to update group:', response.statusText);
        error('Failed to update group');
      }
    } catch (err) {
      console.error('Error updating User:', err);
      error('Error updating User');
    }
  };
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleOpenConfirmation = (userId: any) => {
    setSelectedUserId(userId);
    setOpenConfirmation(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleSearchInputChange = (e: any) => {
    const value = e.target.value;
    setSearchTerm(value);
    setFirstValue(0);
  };

  const fetchData = async (query = '') => {
    try {
      const response1 = await ApiHttpClient.get(
        orgId !== loginOrgId
          ? `admin/${loginOrgId}/console/whoami?currentRealm=${orgId}`
          : `admin/${orgId}/console/whoami?currentRealm=${orgId}`,
      );
      const nameResponse1 = await ApiHttpClient.get(`admin/realms/${loginOrgId}/ui-ext/realms/names?`);
      let reformedState: any = {
        ...state,
        userId: response1.userId,
        realms: nameResponse1,
        realmAccess: response1.realm_access,
      };
      dispatch(setConfigSlice(reformedState));

      const url = query
        ? `admin/realms/${orgId}/users?briefRepresentation=true&first=${firstValue}&max=${rowsPerPage!}&search=${query}`
        : `admin/realms/${orgId}/users?first=${firstValue}&max=${rowsPerPage}&q=a&search=${query}`;
      const paginationUrl =
        query == ''
          ? `admin/realms/${orgId}/users/count?search=${query}`
          : `admin/realms/${orgId}/users/count?q=&search=${query}`;
      const response = await ApiHttpClient.get(url);
      const paginationResponse = await ApiHttpClient.get(paginationUrl);
      setCount(paginationResponse);

      const apiData = response;

      if (apiData) {
        setRows(apiData);
      } else {
        console.error('API returned undefined data');
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }

    try {
      const url = `admin/realms/${orgId}/authentication/required-actions`;
      const response = await ApiHttpClient.get(url);
      const actionsData = response;
      if (actionsData) {
        const names = actionsData.map((action: any) => action.name);
        setActionNames(names);
      } else {
        console.error('API returned undefined actions data');
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const deleteUser = async (id: any) => {
    try {
      const url = `admin/realms/${orgId}/users/${id}`;
      await ApiHttpClient.delete(url, {});
      success('User deleted successfully');
      fetchData('');
      setOpenConfirmation(false);
      setSearchTerm('');
    } catch (err) {
      console.error('Error deleting user:', err);
      error('Error deleting user:');
      setOpenConfirmation(false);
    }
  };

  // Table data code starts

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: '7px !important',
    },
  }));

  function createData(
    SNo: number,
    id: string,
    Username: string,
    Email: string,
    FirstName: string,
    LastName: string,
    enabled: boolean,
  ) {
    return { SNo, id, Username, Email, FirstName, LastName, enabled };
  }

  // Table data code ends

  // Modal pop up code starts

  const [formData, setFormData] = useState<any>({
    username: '',
    firstName: '',
    lastName: '',
    email: '',
  });

  const [errors, setErrors] = useState({
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  const handleClickOpen = () => {
    setSearchTerm('');
    setOpenModal(true);
    handleAutoGeneratePassword();
  };
  const handleClose = () => {
    setOpenModal(false);
    setErrors({
      username: '',
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
    });
    setFormData({
      username: '',
      firstName: '',
      lastName: '',
      email: '',
    });
  };

  const handleChange = (event: { target: { name: any; value: any } }) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    let error = '';
    switch (name) {
      case 'username':
        error = value.trim() ? '' : 'Username is required';
        break;
      case 'firstName':
        error = value.trim() ? '' : 'First Name is required';
        break;
      case 'lastName':
        error = value.trim() ? '' : 'Last Name is required';
        break;
      case 'email':
        error = /\S+@\S+\.\S+/.test(value) ? '' : 'Invalid email address';
        break;
      case 'password':
        error =
          value.length < 8
            ? 'Password  should be at least 8 characters'
            : value !== formData.confirmPassword
            ? 'Passwords do not match'
            : '';
        break;
      case 'confirmPassword':
        error = value.trim() && value === formData.password ? '' : 'Passwords do not match';
        break;
      default:
        break;
    }
    setErrors({ ...errors, [name]: error });
    isFormValid();
  };

  const handleSubmit = async () => {
    let formValid = true;
    const newErrors: any = { ...errors };
    Object.keys(formData).forEach((key) => {
      if (!formData[key].trim()) {
        newErrors[key] = `${key.charAt(0).toUpperCase() + key.slice(1)} is required`;
        formValid = false;
      } else {
        newErrors[key] = '';
      }
    });

    if (formData.email.trim() && !/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Invalid email address';
      formValid = false;
    }

    setErrors(newErrors);

    if (formValid) {
      try {
        const url = `admin/realms/${orgId}/users`;
        const response = await ApiHttpClient.post(url, {
          username: formData.username,
          firstName: formData.firstName,
          lastName: formData.lastName,
          enabled: true,
          email: formData.email,
          requiredActions: personName,
        });
        const getUrl = `admin/realms/${orgId}/users?search=${formData.username}`;
        const getResponse = await ApiHttpClient.get(getUrl);
        fetchData('');
        handleClose();
        const desiredIdObj = getResponse.filter((ele: any) => {
          if (ele.username.toLowerCase() === formData.username.toLowerCase()) {
            return ele;
          }
        });
        if (desiredIdObj.length > 0) {
          const desiredId = desiredIdObj[0]?.id;
          const url1 = `admin/realms/${orgId}/users/${desiredId}/reset-password`;
          const response1 = await ApiHttpClient.put(url1, {
            temporary: 'false',
            type: 'password',
            value: formData.password,
          });
        }
        fetchData('');
        handleClose();
        setFormData({
          username: '',
          firstName: '',
          lastName: '',
          email: '',
        });
        setPersonName([]);
        success('User created successfully');
      } catch (err: any) {
        console.error('Error creating user:', err);
        error(err?.response?.data || 'Error creating user');
      }
    } else {
      error('Form has errors. Please correct them.');
    }
  };

  const handleCopy = () => {
    setShowTooltip(true);
    setTimeout(() => setShowTooltip(false), 3000);
  };

  const handleAutoGeneratePassword = (length = 12) => {
    const minLength = 4;
    const maxLength = 16;

    length = Math.max(minLength, Math.min(length, maxLength));

    const uppercaseLetters = lowercaseLetters.toUpperCase();
    const allChars = lowercaseLetters + uppercaseLetters + numbers + specialChars;

    const allCharsLength = allChars.length;
    let password = '';

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * allCharsLength);
      password += allChars.charAt(randomIndex);
    }

    setPasswordGenerated(password);

    setFormData({
      ...formData,
      password: password,
      confirmPassword: password,
    });

    return password;
  };

  const handleChangePage = (event: any, newPage: any) => {
    const firstValue = newPage * rowsPerPage;
    setFirstValue(firstValue);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFirstValue(0);
  };
  const isFormValid = () => {
    const hasErrors = Object.values(errors).some((error) => error !== '');
    const hasEmptyFields = Object.values(formData).some((field: any) => field.trim() === '');
    return !hasErrors && !hasEmptyFields;
  };
  // Modal pop up code Ends

  return (
    <>
      <Grid sx={{ margin: 5, marginTop: 10 }}>
        <Layout>
          {loading ? (
            <SkeletonLoader rows={''} />
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12} lg={9}>
                <Typography variant="h6">Users</Typography>
                <Typography variant="body1">
                  <Typography component="span" color={'red'}>
                    *Note:
                  </Typography>{' '}
                  Users are created at the organization level, and you will see all the users that have been created by
                  other apps.
                </Typography>
              </Grid>
              <Grid item xs={12} lg={3}>
                <Typography variant="h6" textAlign="right">
                  Org: {orgId.toUpperCase()}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  size="small"
                  fullWidth
                  id="outlined-basic"
                  label="Search User"
                  variant="outlined"
                  autoComplete="off"
                  value={searchTerm}
                  onChange={handleSearchInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} textAlign="right">
                {accessArray.includes('manage-users') && (
                  <Button size="medium" variant="contained" onClick={handleClickOpen}>
                    Create User
                  </Button>
                )}
              </Grid>

              <Grid item xs={12}>
                <TableContainer component={Paper} sx={{ maxHeight: '60vh' }}>
                  <Table sx={{ minWidth: 700 }}>
                    <TableHead sx={{ position: 'sticky', top: 0, zIndex: 1 }}>
                      <TableRow>
                        <StyledTableCell>S.No </StyledTableCell>
                        <StyledTableCell>Username</StyledTableCell>
                        <StyledTableCell>Email</StyledTableCell>
                        <StyledTableCell>First Name</StyledTableCell>
                        <StyledTableCell>Last Name</StyledTableCell>
                        <StyledTableCell>Status</StyledTableCell>
                        <StyledTableCell>Action</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows?.length > 0 &&
                        rows.map((row: any, index) => (
                          <StyledTableRow key={row.id}>
                            <StyledTableCell component="th" scope="row">
                              {page * rowsPerPage + index + 1}
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              <Link
                                underline="hover"
                                key="1"
                                color="primary"
                                component={RouterLink}
                                to={`/auth/userdashboard/${orgId}/users/${row.id}/settings`}
                              >
                                {row.username}
                              </Link>
                              {/* {row.username} */}
                            </StyledTableCell>
                            <StyledTableCell>{row.email}</StyledTableCell>
                            <StyledTableCell>{row.firstName}</StyledTableCell>
                            <StyledTableCell>{row.lastName}</StyledTableCell>
                            <StyledTableCell>
                              <FormControlLabel
                                control={
                                  <Switch
                                    disabled={!accessArray.includes('manage-users')}
                                    onChange={() => handleDisable(row)}
                                    checked={row?.enabled}
                                  />
                                }
                                label=""
                              />
                            </StyledTableCell>
                            <StyledTableCell>
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                {' '}
                                <Typography sx={{ cursor: 'pointer' }} component="span">
                                  <IconButton
                                    color="error"
                                    onClick={() => handleOpenConfirmation(row.id)}
                                    disabled={!accessArray.includes('manage-users')}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Typography>
                                <Button
                                  disabled={!accessArray.includes('manage-users')}
                                  onClick={() => handleResetPassword(row.id)}
                                  variant="outlined"
                                >
                                  Reset Password
                                </Button>
                              </Box>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  component="div"
                  count={count}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={(event) => handleChangeRowsPerPage(event)}
                  rowsPerPageOptions={paginationRowsPerPage}
                  labelRowsPerPage={'Users per Page'}
                />
              </Grid>
            </Grid>
          )}

          <Model
            open={openModal}
            handeleConfirm={handleSubmit}
            title={'Create User'}
            saveButtonName={isFormValid() ? 'Create' : 'Create'}
            data-modal-backdrop="static"
            height="auto"
            //  buttonState={isSubmit}
            cancelClick={() => handleClose()}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="demo-multiple-chip-label">Required User Actions</InputLabel>
                  <Select
                    size="small"
                    fullWidth
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    value={personName}
                    onChange={(e) => setPersonName(e.target.value)}
                    input={<OutlinedInput id="select-multiple-chip" label="Name" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value: any) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                  >
                    {actionNames.map((name) => (
                      <MenuItem key={name} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  fullWidth
                  id="username"
                  name="username"
                  label="Username*"
                  variant="outlined"
                  value={formData.username}
                  onChange={handleChange}
                  error={!!errors.username}
                  helperText={errors.username}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <TextField
                  size="small"
                  fullWidth
                  id="firstName"
                  name="firstName"
                  label="First Name"
                  variant="outlined"
                  value={formData.firstName}
                  onChange={handleChange}
                  error={!!errors.firstName}
                  helperText={errors.firstName}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <TextField
                  size="small"
                  fullWidth
                  id="lastName"
                  name="lastName"
                  label="Last Name"
                  variant="outlined"
                  value={formData.lastName}
                  onChange={handleChange}
                  error={!!errors.lastName}
                  helperText={errors.lastName}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  fullWidth
                  id="email"
                  name="email"
                  label="Email"
                  variant="outlined"
                  value={formData.email}
                  onChange={handleChange}
                  error={!!errors.email}
                  helperText={errors.email}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  onChange={handleChange}
                  name="password"
                  value={formData.password}
                  size="small"
                  label="Password"
                  fullWidth
                  autoComplete="off"
                  error={!!errors.password}
                  helperText={errors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CopyToClipboard text={formData.password} onCopy={handleCopy}>
                          <IconButton
                            sx={{
                              '&:hover': {
                                backgroundColor: 'transparent',
                              },
                            }}
                            edge="end"
                          >
                            <Tooltip
                              title={showTooltip ? ACTIONS.PasswordCopied : ACTIONS.copyPassword}
                              placement={showTooltip ? 'top' : 'bottom'}
                            >
                              <FileCopyIcon sx={{ fontSize: '18px' }} />
                            </Tooltip>
                          </IconButton>
                        </CopyToClipboard>
                        <IconButton
                          sx={{
                            '&:hover': {
                              backgroundColor: 'transparent',
                            },
                          }}
                          onClick={() => {
                            handleAutoGeneratePassword();
                          }}
                          edge="end"
                        >
                          <Tooltip title="Reset Password" placement="bottom">
                            <RestartAltIcon sx={{ fontSize: '18px' }} />
                          </Tooltip>
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {/* <FormHelperText sx={{ color: "red" }}>
                    {errors.password}
                  </FormHelperText> */}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  onChange={handleChange}
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  size="small"
                  label="Confirm Password"
                  fullWidth
                  autoComplete="off"
                  error={!!errors.confirmPassword}
                  helperText={errors.confirmPassword}
                />
                {/* <FormHelperText sx={{ color: "red" }}>
                    {errors.confirmPassword}
                  </FormHelperText> */}
              </Grid>
            </Grid>
          </Model>

          <ConfirmationDialog
            open={openConfirmation}
            onClose={() => setOpenConfirmation(false)}
            title="Confirm Deletion"
            content="Are you sure you want to delete this user?"
            onConfirm={() => deleteUser(selectedUserId)}
          />
          <Model
            open={openResetModal}
            handeleConfirm={resetPassword}
            width="450"
            height="auto"
            title={'Reset password'}
            saveButtonName={'Save'}
            data-modal-backdrop="static"
            cancelClick={() => setResetModal(false)}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  fullWidth
                  label="Password"
                  type="password"
                  variant="outlined"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  fullWidth
                  label="Confirm password"
                  type="password"
                  variant="outlined"
                  required
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch checked={temporary} onChange={(e) => setTemporary(e.target.checked)} color="primary" />
                  }
                  label="Temporary"
                />
              </Grid>
            </Grid>
          </Model>
        </Layout>
      </Grid>
    </>
  );
}

export default UsersList;
