import {
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  styled,
  tableCellClasses,
} from '@mui/material';

import { StyledTableRow } from '../../styles/tableStyles';
import { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import SkeletonLoader from '../SkeletonLoader';
import { ApiHttpClient } from '../../interceptor/axiosInterceptor';
import { useSelector } from 'react-redux';
import useDebouncedSearch from '../../hooks/useDebouncedSearch';
import { error, success } from '../Toast';
import { pagination } from '../../utils/pagination';
import { paginationRowsPerPage } from '../../const/strings';
import { useParams } from 'react-router-dom';

function ClientsScope() {
  const state = useSelector((state: any) => state.config);
  const orgId = state.orgId;
  const accessArray = Object.values(state.realmAccess ?? {}).flat();
  const clientState = useSelector((state: any) => state.client);
  const [count, setCount] = useState(10);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [firstValue, setFirstValue] = useState(0);
  const [scopes, SetClientScopes] = useState([]);
  const [defaultclient, SetDefaultClient] = useState([]);
  const [optionalclient, SetOptionalClient] = useState([]);
  const { clientId } = useParams();
  const [searchTerm, setSearchTerm] = useDebouncedSearch((debouncedTerm: any) => {
    fetchData(debouncedTerm);
  });
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetchData(searchTerm);
  }, [orgId, page, rowsPerPage]);

  const fetchData = async (query = '') => {
    try {
      const url2 = `admin/realms/${orgId}/clients/${clientId}/default-client-scopes`;
      const response2 = await ApiHttpClient.get(url2);
      const clientData: any = response2.map((ele: any, ind: any) => ({ ...ele, key: 'default' }));
      const url1 = `admin/realms/${orgId}/clients/${clientId}/optional-client-scopes`;
      const response1 = await ApiHttpClient.get(url1);
      const optionalClient: any = response1.map((ele: any) => ({ ...ele, key: 'optional' }));
      const url = query
        ? `/admin/realms/${orgId}/client-scopes?first=${firstValue}&max=${rowsPerPage!}&search=${query}`
        : `/admin/realms/${orgId}/client-scopes?first=${firstValue}&max=${rowsPerPage!}`;
      // const paginationUrl = query
      //   ? `/admin/realms/${orgId}/client-scopes?search=${query}`
      //   : `/admin/realms/${orgId}/client-scopes`;
      // const paginationResponse = await ApiHttpClient.get(paginationUrl);
      const response = await ApiHttpClient.get(url);
      const filteredValues = response.filter(
        (item: any) =>
          item.name.toLowerCase().includes(query.toLowerCase()) ||
          item.description.toLowerCase().includes(query.toLowerCase()) ||
          item.protocol.toLowerCase().includes(query.toLowerCase()),
      );
      const paginatedResponse = pagination(query ? filteredValues : response, page, rowsPerPage);
      query ? setCount(filteredValues.length) : setCount(response.length);
      let apiData: any = paginatedResponse;
      let totalData = paginatedResponse.map((ele: any) => {
        let isDefault = clientData.find((item: any) => item.id === ele.id);
        let isOptional = optionalClient.find((item: any) => item.id === ele.id);
        return {
          ...ele,
          key: isDefault ? 'default' : isOptional ? 'optional' : 'none',
        };
      });
      const totalClientData: any = [...optionalClient, ...clientData];
      totalClientData.length > 0 ? SetClientScopes(totalData) : SetClientScopes(apiData);
      SetOptionalClient(optionalClient);
      SetDefaultClient(clientData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleChange = async (e: any, obj: any) => {
    const value = e.target.value;
    if (value.toLowerCase() !== obj.key) {
      try {
        const deleteUrl =
          value.toLowerCase() === 'optional'
            ? `/admin/realms/${orgId}/clients/${clientId}/default-client-scopes/${obj.id}`
            : `/admin/realms/${orgId}/clients/${clientId}/optional-client-scopes/${obj.id}`;

        const updateUrl =
          value.toLowerCase() === 'optional'
            ? `/admin/realms/${orgId}/clients/${clientId}/optional-client-scopes/${obj.id}`
            : `/admin/realms/${orgId}/clients/${clientId}/default-client-scopes/${obj.id}`;

        await ApiHttpClient.delete(deleteUrl, {});
        await ApiHttpClient.put(updateUrl, {});

        const updatedScopes: any = scopes.map((scope: any) =>
          scope.id === obj.id ? { ...scope, key: value.toLowerCase() } : scope,
        );
        SetClientScopes(updatedScopes);
        success('Scope mapping updated');
      } catch (err) {
        console.error('Error changing assignment type:', err);
        error('Error in Scope mapping');
      }
    }
  };

  const handleSearchInputChange = (e: any) => {
    const value = e.target.value;
    setSearchTerm(value);
    setFirstValue(0);
    setPage(0);
  };

  const handleChangePage = (event: any, newPage: any) => {
    const firstValue = newPage * rowsPerPage;
    setFirstValue(firstValue);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFirstValue(0);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: '7px !important',
    },
  }));

  return (
    <>
      {loading ? (
        <SkeletonLoader rows={''} />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={4} sm={6}>
            <TextField
              size="small"
              fullWidth
              id="outlined-basic"
              label="Search by name"
              variant="outlined"
              value={searchTerm}
              onChange={handleSearchInputChange}
            />
          </Grid>
          {/* <Grid item xs={4} sm={6} textAlign="right">
            <Button size="medium" variant="contained">
              Add client scope
            </Button>
          </Grid> */}

          <Grid item xs={12}>
            <TableContainer component={Paper} sx={{ maxHeight: '60vh' }}>
              <Table sx={{ minWidth: 700 }}>
                <TableHead sx={{ position: 'sticky', top: 0, zIndex: 1 }}>
                  <TableRow>
                    <StyledTableCell>S.No </StyledTableCell>
                    <StyledTableCell>name </StyledTableCell>
                    <StyledTableCell>Assigned type </StyledTableCell>
                    <StyledTableCell>Protocol </StyledTableCell>
                    <StyledTableCell>Description </StyledTableCell>
                    <StyledTableCell>Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {scopes.map((scopes: any, index: any) => (
                    <StyledTableRow key={scopes.id}>
                      <StyledTableCell component="th" scope="row">
                        {page * rowsPerPage + index + 1}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {scopes.name}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        <Select
                          size="small"
                          fullWidth
                          value={scopes.key}
                          disabled={!accessArray.includes('manage-clients')}
                          onChange={(e: any) => handleChange(e, scopes)}
                        >
                          <MenuItem value="default">Default</MenuItem>
                          <MenuItem value="optional">Optional</MenuItem>
                        </Select>
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {scopes.protocol}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {scopes.description}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography sx={{ cursor: 'pointer' }} component="span" color={'red'}>
                          <IconButton disabled={!accessArray.includes('manage-clients')} color="error">
                            <DeleteIcon />
                          </IconButton>
                        </Typography>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={count}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={(event) => handleChangeRowsPerPage(event)}
              rowsPerPageOptions={paginationRowsPerPage}
              labelRowsPerPage={'Scopes per Page'}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default ClientsScope;
