import { Box, Breadcrumbs, Grid, Link, Stack, Tab, Tabs, Typography } from '@mui/material';
import Layout from '../Layout';
import React, { useRef } from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';
import ClientSettings from './ClientSettings';
import ClientsRole from './ClientsRole';
import ClientsScope from './ClientsScope';
import Credentials from './Credentials';
import { ApiHttpClient } from '../../interceptor/axiosInterceptor';
import { ChangeEvent, useEffect, useState } from 'react';
import { setConfigSlice } from '../../store/realmSlice';
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function ClientDetails() {
  const [value, setValue] = React.useState(0);
  const [client, setClient] = useState<any>('');
  const state = useSelector((state: any) => state.config);
  const initialState = useSelector((state: any) => state.initialConfig);
  const loginOrgId = initialState.orgId;
  const orgId = state.orgId;
  const dispatch = useDispatch();
  const clientState = useSelector((state: any) => state.client);
  const { clientId } = useParams();
  const [isCredEnable, SetisCredEnable] = useState(false);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const fetchData = async () => {
    try {
      const response1 = await ApiHttpClient.get(
        orgId !== loginOrgId
          ? `admin/${loginOrgId}/console/whoami?currentRealm=${orgId}`
          : `admin/${orgId}/console/whoami?currentRealm=${orgId}`,
      );
      const nameResponse1 = await ApiHttpClient.get(`admin/realms/${loginOrgId}/ui-ext/realms/names?`);
      let reformedState: any = {
        ...state,
        userId: response1.userId,
        realms: nameResponse1,
        realmAccess: response1.realm_access,
      };
      dispatch(setConfigSlice(reformedState));
      const url = `admin/realms/${orgId}/clients/${clientId}`;
      const response = await ApiHttpClient.get(url);
      setClient(response ? response.clientId : '');
      let apiData: any = response;
      if (apiData?.publicClient === false && apiData?.secret) {
        SetisCredEnable(true);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [orgId]);

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="primary"
      component={RouterLink}
      to={`/auth/userdashboard/${loginOrgId !== orgId ? orgId : loginOrgId}/clients`}
    >
      Clients
    </Link>,
    <Typography key="2" color="text.primary">
      Client details
    </Typography>,
  ];

  return (
    <>
      <Grid sx={{ margin: 5, marginTop: 10 }}>
        <Layout>
          <Stack spacing={2} sx={{ marginTop: '10px' }}>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
              {breadcrumbs}
            </Breadcrumbs>
          </Stack>
          <Grid container spacing={3} sx={{ marginTop: '3px', paddingBottom: '15px' }}>
            <Grid item xs={12} lg={9}>
              <Typography variant="h5">{client}</Typography>
              <Typography variant="body1">
                <Typography component="span" color={'red'}>
                  *Note:
                </Typography>{' '}
                Clients are applications and services that can request authentication of a user.
              </Typography>
            </Grid>
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="Settings" {...a11yProps(0)} />
                  <Tab label="Roles" {...a11yProps(1)} />
                  <Tab label="Client Scopes" {...a11yProps(2)} />
                  {isCredEnable && <Tab label="Credentials" {...a11yProps(3)} />}
                </Tabs>
              </Box>

              <CustomTabPanel value={value} index={0}>
                <ClientSettings client={client} />
              </CustomTabPanel>

              <CustomTabPanel value={value} index={1}>
                <ClientsRole client={client} />
              </CustomTabPanel>

              <CustomTabPanel value={value} index={2}>
                <ClientsScope />
              </CustomTabPanel>

              {isCredEnable && (
                <CustomTabPanel value={value} index={3}>
                  <Credentials />
                </CustomTabPanel>
              )}
            </Box>
          </Grid>
        </Layout>
      </Grid>
    </>
  );
}

export default ClientDetails;
