import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: '450px',
    maxWidth: '90%',
  },
  '& .MuiDialogTitle-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function Model({
  open,
  children,
  title,
  handeleConfirm,
  saveButtonName = '',
  cancelClick = () => {},
  width = '450px',
  height = '',
}: any) {
  const handleDialogClose = () => {
    cancelClick();
  };

  return (
    <Grid>
      <BootstrapDialog
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          '& .MuiDialog-paper': {
            width: width,
            maxWidth: '90%',
            height: height,
          },
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {title}
          <IconButton
            aria-label="close"
            onClick={handleDialogClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>{children}</DialogContent>
        <DialogActions>
          <Button size="medium" variant="outlined" onClick={handleDialogClose}>
            Cancel
          </Button>
          {saveButtonName && (
            <Button size="medium" variant="contained" onClick={handeleConfirm}>
              {saveButtonName}
            </Button>
          )}
        </DialogActions>
      </BootstrapDialog>
    </Grid>
  );
}
