export const getRequiredPermissionsForRoute = (route: string): string[] => {
  const routePermissionsMap: { pattern: RegExp; permissions: string[] }[] = [
    { pattern: /^\/auth\/dashboard$/, permissions: [] },
    { pattern: /^\/auth\/userdashboard\/[^/]+\/clients$/, permissions: ['view-clients'] },
    { pattern: /^\/auth\/userdashboard\/[^/]+\/clientscopes$/, permissions: ['view-clients'] },
    { pattern: /^\/auth\/userdashboard\/[^/]+\/users$/, permissions: ['view-users', 'manage-users'] },
    { pattern: /^\/auth\/userdashboard\/[^/]+\/roles$/, permissions: ['view-realm'] },
    { pattern: /^\/auth\/userdashboard\/[^/]+\/groups$/, permissions: ['query-groups'] },
    { pattern: /^\/auth\/userdashboard\/[^/]+\/info$/, permissions: ['view-users', 'view-realm'] },
    { pattern: /^\/auth\/userdashboard\/[^/]+\/events$/, permissions: ['view-users', 'view-events'] },
    { pattern: /^\/auth\/userdashboard\/[^/]+\/settings$/, permissions: ['view-realm'] },
    { pattern: /^\/auth\/userdashboard\/[^/]+\/clients\/createclient$/, permissions: ['manage-clients'] },
    {
      pattern: /^\/auth\/userdashboard\/[^/]+\/authentication$/,
      permissions: ['view-identity-providers', 'view-realm', 'view-clients'],
    },
    { pattern: /^\/auth\/userdashboard\/[^/]+\/clients\/[^/]+\/clientdetails$/, permissions: ['view-clients'] },
    { pattern: /^\/auth\/userdashboard\/[^/]+\/groups\/groupschild\/[^/]+$/, permissions: ['query-groups'] },
    { pattern: /^\/auth\/userdashboard\/[^/]+\/groups\/groupsdetails\/[^/]+$/, permissions: ['query-groups'] },
    { pattern: /^\/auth\/userdashboard\/[^/]+\/users\/[^/]+\/settings$/, permissions: ['view-users', 'manage-users'] },
  ];

  for (const { pattern, permissions } of routePermissionsMap) {
    if (pattern.test(route)) {
      return permissions;
    }
  }

  return [];
};
