import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ApiHttpClient } from '../../interceptor/axiosInterceptor';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { error, success } from '../Toast';

function GroupsAttributes() {
  const state = useSelector((state: any) => state.config);
  const orgId = useMemo(() => state.orgId, [state.orgId]);
  const accessArray = Object.values(state.realmAccess ?? {}).flat();
  const { clientid } = useParams();
  const [rows, setMembers] = useState([]);
  const [attributeFields, setAttributeFields] = useState([{ key: '', value: '' }]);
  const [errors, setErrors] = useState([{ key: false, value: false }]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const groupName = queryParams.get('groupName');

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const url = `admin/realms/${orgId}/groups/${clientid}`;
      const response = await ApiHttpClient.get(url);
      const apiData = response;
      if (apiData) {
        setMembers(apiData);
        const attributesArray = Object.entries(apiData.attributes || {}).map(([key, value]: any) => ({
          key,
          value: value[0],
        }));
        setAttributeFields(attributesArray);
        setErrors(attributesArray.map(() => ({ key: false, value: false })));
      } else {
        console.error('API returned undefined data');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleAddAttribute = () => {
    setAttributeFields([...attributeFields, { key: '', value: '' }]);
    setErrors([...errors, { key: false, value: false }]);
  };

  const handleRemoveAttribute = (index: number) => {
    const updatedFields = attributeFields.filter((_, i) => i !== index);
    const updatedErrors = errors.filter((_, i) => i !== index);
    setAttributeFields(updatedFields);
    setErrors(updatedErrors);
  };

  const handleAttributeChange = (index: number, field: string, value: string) => {
    const updatedFields: any = [...attributeFields];
    updatedFields[index][field] = value;
    setAttributeFields(updatedFields);
    const updatedErrors: any = [...errors];
    updatedErrors[index][field] = value === '';
    setErrors(updatedErrors);
  };

  const handleSaveAttributes = async () => {
    const updatedErrors = attributeFields.map((attr) => ({
      key: attr.key === '',
      value: attr.value === '',
    }));
    setErrors(updatedErrors);

    const hasErrors = updatedErrors.some((err) => err.key || err.value);
    if (hasErrors) {
      error('Please fill all the fields');
      return;
    }

    const attributesPayload = attributeFields.reduce((acc: any, { key, value }) => {
      if (key) acc[key] = [value];
      return acc;
    }, {});
    const payload = {
      attributes: attributesPayload,
      clientRoles: {},
      id: clientid,
      name: groupName,
      path: `/${groupName}`,
      realmRoles: [],
      subGroupCount: 3,
      subGroups: [],
    };

    try {
      const url = `admin/realms/${orgId}/groups/${clientid}`;
      await ApiHttpClient.put(url, payload);

      success('Group updated');
    } catch (err) {
      console.error('Error updating attributes:', err);
      error('Error in Group update');
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          padding: 2,
        }}
      >
        {attributeFields.length > 0 ? (
          attributeFields.map((attr, index) => (
            <Grid container spacing={3} key={index} sx={{ marginTop: index > 0 ? '8px' : '0' }}>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Key"
                  variant="outlined"
                  size="small"
                  value={attr.key}
                  onChange={(e) => handleAttributeChange(index, 'key', e.target.value)}
                  error={errors[index].key}
                  helperText={errors[index].key && 'Key is required'}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Value"
                  variant="outlined"
                  value={attr.value}
                  size="small"
                  onChange={(e) => handleAttributeChange(index, 'value', e.target.value)}
                  error={errors[index].value}
                  helperText={errors[index].value && 'Value is required'}
                />
              </Grid>
              <Grid item xs={2}>
                <RemoveCircleOutlineIcon
                  sx={{
                    cursor: accessArray.includes('manage-users') ? 'pointer' : 'not-allowed',
                    marginTop: '5px',
                    color: accessArray.includes('manage-users') ? 'primary' : 'gray',
                  }}
                  color="primary"
                  onClick={() => accessArray.includes('manage-users') && handleRemoveAttribute(index)}
                />
              </Grid>
            </Grid>
          ))
        ) : (
          <Grid item xs={12} sm={6}>
            <>
              <Typography variant="body1" gutterBottom>
                No attributes have been defined yet.
              </Typography>
              <Typography variant="body2" gutterBottom>
                Click the below button to add attributes, key and value are required for a key pair.
              </Typography>
            </>
          </Grid>
        )}
      </Box>
      <Grid container spacing={3} sx={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}>
        <Grid item xs={2}>
          <Button
            disabled={!accessArray.includes('manage-users')}
            startIcon={<AddCircleIcon />}
            onClick={handleAddAttribute}
          >
            Add Attribute
          </Button>
        </Grid>
        <Grid item xs={1}>
          <Button
            disabled={!accessArray.includes('manage-users')}
            variant="contained"
            color="primary"
            onClick={handleSaveAttributes}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default GroupsAttributes;
